import { memo, useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import Badge from "app/pages/.shared/Badge";
import AppGlobalsContext from "app/AppGlobalsContext";
import { STRATEGY } from "app/constants";

const ProductTopBar = ({
	isFlashsale = {},
	productExpirationHours,
	productExpirationDays,
	showSmartDPOnRootUrl,
}) => {
	const { strategy } = useContext(AppGlobalsContext);

	return (
		<div className="product-top-bar">
			<Badge>
				{isFlashsale && (
					<Timer
						hour={productExpirationHours}
						day={productExpirationDays}
						suffix={<FormattedMessage id="timer.left.label" />}
					/>
				)}
				{showSmartDPOnRootUrl && strategy !== STRATEGY.TRANSACTION_FIRST && (
					<div>
						<FormattedMessage id="sdp.only.for.member.badge.label" />
					</div>
				)}
			</Badge>
		</div>
	);
};

ProductTopBar.propTypes = {
	isFlashsale: PropTypes.bool,
	productExpirationHours: PropTypes.number,
	productExpirationDays: PropTypes.number,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(ProductTopBar);
