import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedPlural } from "react-intl";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { OFFER_PRICE_TYPES } from "app/constants";
import { fromPriceTypeShape } from "app/utils/propTypes";
import "./From.scss";
import IconPlane2 from "app/pages/.shared/IconPlane2";

const From = ({
	travelByTrainAvailable,
	value,
	pricePrefix,
	priceSuffix,
	publicPrice,
	offerPriceType,
	includeOfferWithFlight,
	publicPricePrefix,
	publicPriceSuffix,
	isWithHotel,
	duration,
	showFromInfo = true,
}) => {
	const nightCount = { count: duration };

	return (
		<div className="from">
			<div className="from__price">
				<div className="from__value" data-testid="from-value">
					<AmountContainer
						amount={Number(value)}
						prefix={pricePrefix}
						suffix={priceSuffix}
					/>
				</div>

				{publicPrice > 0 ? (
					<div className="from__public-price" data-testid="from-public-price">
						<AmountContainer
							amount={publicPrice}
							prefix={publicPricePrefix}
							suffix={publicPriceSuffix}
						/>
					</div>
				) : (
					false
				)}
			</div>
			{showFromInfo && (
				<div className="from__info">
					{isWithHotel ? (
						<FormattedPlural
							value={duration}
							one={
								<FormattedMessage
									id="general.hotel.nights.count.singular"
									values={nightCount}
								/>
							}
							other={
								<FormattedMessage
									id="general.hotel.nights.count"
									values={nightCount}
								/>
							}
						/>
					) : (
						<FormattedPlural
							value={duration}
							one={
								<FormattedMessage
									id="general.nights.count.singular"
									values={nightCount}
								/>
							}
							other={
								<FormattedMessage id="general.nights.count" values={nightCount} />
							}
						/>
					)}
					<div className="from__flight">
						{offerPriceType === OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT &&
							includeOfferWithFlight === true && (
								<div className="from__flight--included">
									{travelByTrainAvailable ? (
										<FormattedMessage id="general.included.transport" />
									) : (
										<FormattedMessage id="general.included.flight" />
									)}
									{travelByTrainAvailable === false && (
										<IconPlane2
											width={15}
											height={15}
											className="icon--flight"
										/>
									)}
								</div>
							)}

						{offerPriceType === OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM &&
							includeOfferWithFlight === true &&
							(!travelByTrainAvailable ? (
								<FormattedMessage id="general.excluded.flight" />
							) : (
								<FormattedMessage id="general.excluded.transport" />
							))}
					</div>
				</div>
			)}
		</div>
	);
};

From.propTypes = {
	travelByTrainAvailable: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	includeOfferWithFlight: PropTypes.bool,
	pricePrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	priceSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPricePrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPriceSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	offerPriceType: fromPriceTypeShape,
	isWithHotel: PropTypes.bool,
	duration: PropTypes.number,
	showFromInfo: PropTypes.bool,
};

export default memo(From);
