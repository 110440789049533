import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { memo, useContext, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import Swiper from "react-id-swiper";
import CarouselDots from "app/pages/.shared/Carousel/CarouselDots/CarouselDots";
import CarouselArrow from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/CarouselArrow";
import "./ImagesCarouselPP.scss";
import SwiperLazyImage from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/SwiperLazyImage";
import SwiperLazyBackgroundImage from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/SwiperLazyBackgroundImage";

const CLOUDINARY_OPTIONS = ["c_fill"];

const ImagesCarouselPP = ({
	sizes = {},
	images = [],
	alt,
	productUri,
	handleProductClick = () => {},
	openInNewTab = false,
}) => {
	const { resolution } = useContext(AppGlobalsContext);
	const [currentSlide, setCurrentSlide] = useState(0);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const [swiper, setSwiper] = useState(null);

	const updateSwiper = swiper => {
		setSwiper(swiper);
	};

	const slidePrev = () => {
		swiper.slidePrev();
	};

	const slideNext = () => {
		swiper.slideNext();
	};

	const swiperParams = {
		shouldSwiperUpdate: true,
		getSwiper: updateSwiper,
		slidesPerView: 1,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		loop: true,
		preloadImages: false,
		lazy: { loadPrevNext: true },
	};

	useEffect(() => {
		if (swiper) {
			swiper.on("slideChangeTransitionStart", () => {
				let currentSlide = swiper?.activeIndex - 1;
				if (swiper?.activeIndex > images.length) {
					currentSlide = 0;
				}
				if (swiper?.activeIndex === 0) {
					currentSlide = images.length - 1;
				}
				setCurrentSlide(currentSlide);
			});
		}

		return () => {
			if (swiper) {
				swiper.off("slideChangeTransitionEnd");
			}
		};
	}, [swiper]);

	return (
		<LazyLoad once offset={1000}>
			<div className="images-carousel-pp">
				<Swiper {...swiperParams}>
					{images.map((image, index) => {
						return (
							<div className="images-carousel-pp__image" key={index}>
								<ProductLinkContainer
									handleProductClick={handleProductClick}
									productUri={productUri}
									openOnNewTab={openInNewTab}
								>
									{isMobile ? (
										<SwiperLazyBackgroundImage
											sizes={sizes}
											cloudinaryOptions={CLOUDINARY_OPTIONS}
											alt={alt}
											src={image?.url}
											productUri={productUri}
											resolution={resolution}
										/>
									) : (
										<SwiperLazyImage
											sizes={sizes}
											alt={alt}
											src={image?.url}
											productUri={productUri}
											resolution={resolution}
										/>
									)}
								</ProductLinkContainer>
							</div>
						);
					})}
				</Swiper>
				{images?.length > 1 && swiper && (
					<>
						<div className="images-carousel-pp__button--next" onClick={slideNext}>
							<CarouselArrow orientation="right" className="images-carousel-pp" />
						</div>
						<div className="images-carousel-pp__button--back" onClick={slidePrev}>
							<CarouselArrow orientation="left" className="images-carousel-pp" />
						</div>
						<CarouselDots
							currentSlide={currentSlide}
							totalSlides={images?.length}
							size={isMobile ? 7 : 8}
						/>
					</>
				)}
			</div>
		</LazyLoad>
	);
};

ImagesCarouselPP.propTypes = {
	sizes: PropTypes.object,
	images: PropTypes.array,
	alt: PropTypes.string,
	productUri: PropTypes.any,
	handleProductClick: PropTypes.func,
	openInNewTab: PropTypes.bool,
};

export default memo(ImagesCarouselPP);
