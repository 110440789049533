import { memo } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import PropTypes from "prop-types";
import { productShape } from "app/utils/propTypes";

const IMG_SIZES_MOBILE = {
	small: { width: 768, height: 416 },
	medium: { width: 768, height: 416 },
};

const IMG_SIZES_DESKTOP = {
	large: { width: 502, height: 240 },
	xlarge: { width: 502, height: 240 },
};

const CLOUDINARY_OPTIONS = ["c_fill"];
const ImageWithoutCarousel = ({
	handleProductClick = () => {},
	product = {},
	openInNewTab,
	desktopImageSizes = IMG_SIZES_DESKTOP,
	mobileImageSizes = IMG_SIZES_MOBILE,
	resolution,
}) => {
	// TODO: Remove this componet when we find a solution for the image slider on mobile due to compatibility issues and carousel swipe.	const mainPhoto = product?.photos && product.photos[0];

	const mainPhoto = product?.photos && product.photos[0];

	return (
		<ProductLinkContainer
			handleProductClick={handleProductClick}
			productUri={product.uri}
			openOnNewTab={openInNewTab}
		>
			{resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL ? (
				<BackgroundProgressiveImg
					sizes={mobileImageSizes}
					cloudinaryOptions={CLOUDINARY_OPTIONS}
					alt={product.country}
					src={mainPhoto && mainPhoto.url}
					thumbnail={mainPhoto && mainPhoto.thumbnail}
					productUri={product?.uri}
				/>
			) : (
				<ProgressiveImg
					className="progressive-img"
					sizes={desktopImageSizes}
					alt={product.country}
					src={mainPhoto && mainPhoto.url}
					thumbnail={mainPhoto && mainPhoto.thumbnail}
					productUri={product?.uri}
				/>
			)}
		</ProductLinkContainer>
	);
};
ImageWithoutCarousel.propTypes = {
	product: productShape,
	desktopImageSizes: PropTypes.object,
	mobileImageSizes: PropTypes.object,
	openInNewTab: PropTypes.bool,
	resolution: PropTypes.string,
	handleProductClick: PropTypes.func,
};
export default memo(ImageWithoutCarousel);
