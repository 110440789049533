import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { productShape } from "app/utils/propTypes";
import { pushTagOnProductViewButtonClick } from "app/utils/analytics";
import "@brand/Listing/Product/product-with-translucent-deal.scss";
import {
	FLAGSHIP_EVENT_KPIS,
	OFFER_CONTRACT_TYPES,
	PRODUCT_EXPERIENCES_WITH_HOTEL,
} from "app/constants";
import BadgeList from "app/pages/.shared/ColoredProductBadge/BadgeList";
import ProductDestinationTitleContainer from "app/pages/.shared/ProductDestinationTitle/ProductDestinationTitleContainer";
import ProductTopBar from "@brand/Product/ProductTopBar";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import ProductDeal from "app/pages/.shared/Deal/ProductDeal";
import AppGlobalsContext from "app/AppGlobalsContext";
import { EventCategory, HitType, useFlagship, useFsFlag } from "@flagship.io/react-sdk";
import { useLocation } from "react-router-dom";
import ProductRatingContainer from "app/pages/FicheProduit/components/ProductRating/ProductRatingContainer";
import ImagesCarouselPP from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/ImagesCarouselPP";
import ImageWithoutCarousel from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/ImageWithoutCarousel";

const IMG_SIZES = {
	small: { width: 768, height: 416 },
	medium: { width: 768, height: 416 },
	large: { width: 502, height: 290 },
	xlarge: { width: 502, height: 290 },
};

const ProductWithTranslucentDeal = ({
	product = {},
	position,
	showSmartDPOnRootUrl,
	displayCardSliderOnMob = true,
}) => {
	const { pathname } = useLocation();
	const { hit: fsHit } = useFlagship();
	const { resolution } = useContext(AppGlobalsContext);

	const handleProductClick = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FLAGSHIP_EVENT_KPIS.CLICK_ON_PP_PRODUCT_CARD,
			label: pathname,
		});
		pushTagOnProductViewButtonClick(product, position + 1);
	}, [product.uri, position, pathname]);

	const productBadgesShow = useFsFlag("product_badges_show", true);
	const isHomePage = pathname.includes("/home");
	const productBadgesShowValue = productBadgesShow.getValue(false);

	if (!isHomePage) {
		productBadgesShow.userExposed();
	}

	const openInNewTab = resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE;

	return (
		<div className="product-with-translucent-deal" data-testid="product">
			<div className="product-with-translucent-deal__picture">
				{!displayCardSliderOnMob ? (
					<ImageWithoutCarousel
						handleProductClick={handleProductClick}
						product={product}
						openInNewTab={openInNewTab}
						resolution={resolution}
					/>
				) : (
					<ImagesCarouselPP
						sizes={IMG_SIZES}
						images={product?.photos}
						alt={product?.name}
						productUri={product?.uri}
						handleProductClick={handleProductClick}
						openInNewTab={openInNewTab}
					/>
				)}

				<div className="product-with-translucent-deal__topbar">
					<ProductTopBar
						isFlashsale={product?.isFlashsale}
						productExpirationHours={product?.expiration?.hour}
						productExpirationDays={product?.expiration?.day}
						showSmartDPOnRootUrl={showSmartDPOnRootUrl}
					/>
				</div>

				<div className="product-with-translucent-deal__deal">
					<ProductDeal
						isRental={product?.contractType === OFFER_CONTRACT_TYPES.RENTAL}
						includeOfferWithFlight={product?.includeOfferWithFlight}
						duration={product?.fromPriceType?.numberOfNights}
						publicPrice={product?.fromPriceType?.publicPrice}
						price={product?.fromPriceType?.price}
						hour={product?.expiration?.hour}
						day={product?.expiration?.day}
						type={product?.fromPriceType?.type}
						value={product?.fromPriceType?.value}
						isWithHotel={PRODUCT_EXPERIENCES_WITH_HOTEL.includes(
							product?.productExperience
						)}
					/>
				</div>
			</div>
			<div className="product-with-translucent-deal__footer">
				<div className="product-with-translucent-deal__info product-with-translucent-deal__info--left">
					<div className="product-with-translucent-deal__main">
						<ProductDestinationTitleContainer
							country={product.country}
							region={product.region}
							resort={product.resort}
						/>
						<ProductNameWithCategory
							categoryType={product.categoryType}
							category={product.category}
							name={product.name}
						/>
					</div>

					<ProductRatingContainer
						tripAdvisor={product.tripadvisor || {}}
						holidayCheck={{
							...(product.holidaycheck || {}),
							size: "small",
						}}
					/>

					{product?.badges?.length > 0 && productBadgesShowValue ? (
						<div className="product-with-translucent-deal__badges">
							<BadgeList badges={product.badges} />
						</div>
					) : (
						false
					)}
				</div>

				<div className="product-with-translucent-deal__info product-with-translucent-deal__info--right">
					<div className="product-with-translucent-deal__action">
						<ProductLinkContainer
							handleProductClick={handleProductClick}
							productUri={product.uri}
							openOnNewTab={openInNewTab}
						>
							<IconRight width={15} height={15} />
						</ProductLinkContainer>
					</div>
				</div>
			</div>
		</div>
	);
};

ProductWithTranslucentDeal.propTypes = {
	position: PropTypes.number,
	product: productShape,
	showSmartDPOnRootUrl: PropTypes.bool,
	displayCardSliderOnMob: PropTypes.bool,
};

export default ProductWithTranslucentDeal;
